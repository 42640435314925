.dndflow {
	flex-direction: column;
	display: flex;
	height: 700px;
}

.dndflow aside {
	padding: 15px 10px;
}

.dndflow aside > * {
	margin-bottom: 10px;
	cursor: grab;
}

.dndflow .reactflow-wrapper {
	flex-grow: 1;
}

.touchdevice-flow .react-flow__handle {
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	background-color: gray;
}

@media screen and (min-width: 768px) {
	.dndflow {
		flex-direction: row;
	}

	.dndflow aside {
		width: 20%;
		max-width: 180px;
	}
}
