.body {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.reactMarkdown {
	margin-top: 2vh;
	margin-left: 2vw;
	width: 85%;
}
.reactMarkdownHeader {
	margin-top: 2vh;
}
.reactMarkdown h4 {
	font-size: large;
	margin-bottom: 2vh;
	font-weight: 350;
}
.reactMarkdown h4 strong {
	font-weight: 700;
}
.reactMarkdownHeader h3 {
	font-size: 1.5rem;
}
.reactMarkdown ol li {
	margin-left: 3em;
	margin-bottom: 0.5vh;
}
.reactMarkdown ol li::marker {
	font-weight: 800;
}
.reactMarkdown p strong {
	font-weight: 800;
}
.accordion-button {
	padding: 0;
	padding-right: 2%;
}
@media (max-width: 1500px) {
	.reactMarkdown h1 {
		text-align: start;
	}
}
@media (max-width: 1300px) {
	.reactMarkdown {
		width: 90%;
	}
}
@media (max-width: 1090px) {
	.reactMarkdown p {
		font-size: 0.95em;
	}
	.reactMarkdown h4 {
		font-size: 1.3em;
	}
}
@media (max-width: 700px) {
	.reactMarkdown ol li {
		margin-left: 1.75em;
	}
}
@media (max-width: 520px) {
	.reactMarkdown p {
		font-size: 0.85em;
	}
	.reactMarkdown h4 {
		font-size: 1.15em;
	}
	.reactMarkdown ol li {
		font-size: 0.85em;
		margin-left: 1.25em;
	}
}
