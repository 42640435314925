/**
 * = Accordions
 */

.accordion-button {
	text-align: left;
	.btn-check:focus + .btn,
	.btn:focus {
		outline: 0;
		box-shadow: none;
	}
	background-color: $primary;

	&:hover {
		background-color: $gray-200;
	}
}

.accordion-item {
	background-color: $primary;
}

// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
	.accordion-button {
		background-color: transparent;
		&:not(.collapsed) {
			color: $secondary;
		}
		&:focus {
			border-color: $gray-300;
			box-shadow: none;
		}
	}
	.accordion-collapse {
		border: 0;
	}
}
